import UiCommon from "./uiCommon";
import { Guid } from "guid-typescript";
import { useAppStore } from "../stores/appStore";
import { isNullOrWhiteSpace } from "./stringUtils";

const ui: UiCommon = new UiCommon();
const badAPIKeyLimit: number = 3;
const baseUrl: string = "/api/v1.0/";
const API_REFRESH_ENDPOINT = "APIKey/GetKey";
let APIKey: string;
let isFetchingApiKey: boolean = false;

type methodType = "get" | "post" | "put" | "delete";

export interface IApiRequest<T> {
  endpoint?: string;
  data?: T;
  method: methodType;
  error?: Function;
  fullUri?: string;
}

export async function fetchDataRequest<T>(request: IApiRequest<T>): Promise<T> {
  return new Promise<T>(async (resolve, reject) =>
    resolve(
      (await fetchData(request.endpoint, request.data, request.error, request.method, request.fullUri)) ??
        ({} as T)
    )
  );
}

export async function fetchData<T>(
  endpoint?: string,
  data?: any,
  error?: Function,
  method: methodType = "post",
  fullUri?: string
): Promise<T | void> {
  APIKey = APIKey === undefined ? getApiKey() : APIKey;
  error = error || errorCallback;

  if (isFetchingApiKey && endpoint !== API_REFRESH_ENDPOINT) {
    return queueApiRequest<T>(Guid.create(), endpoint, data, error, method, fullUri);
  }
  if (!endpoint && !fullUri) {
    console.error("No endpoint or fullUri provided to fetchData");
    return;
  }

  return new Promise<T>(async (resolve, reject) => {
    try {
      var requestUrl = fullUri ? fullUri : `${baseUrl}${endpoint}`;
      const res = await fetch(addGlobalParams(`${requestUrl}`), {
        method: method,
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-ApiKey": APIKey,
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "same-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: data ? JSON.stringify(data) : "",
      })
        .then((res) => {
          // a non-200 response code
          if (!res.ok) {
            if (res.status === 403) {
              refreshApiKey(Guid.create(), resolve, res, endpoint!, data, error);
            } else {
              // create error instance with HTTP status text
              const newError = new Error(res.statusText);
              throw newError;
            }
          } else {
            resolve(res.json());
          }
        })
        .catch((err) => {
          if (error) {
            reject(error(err));
          } else {
            reject(err);
          }
        });
    } catch (err) {
      if (error) {
        reject(error(err));
      } else {
        reject(err);
      }
    }
  });
}

const refreshApiKey = <T>(
  requestId: Guid,
  promiseSuccess: Function,
  apiFailedResp: Response,
  endpoint: string,
  data?: any,
  error?: Function
): void => {
  apiFailedResp.text().then((t) => {
    if (t && t.trim().toLowerCase() === "invalid or expired api key ('x-apikey' http header)") {
      isFetchingApiKey = true;
      console.info(`Api key expired, queuing api request ${requestId} while new key is fetched`);
      queueApiRequest<T>(requestId, endpoint, data, error).then((x) => {
        if (x) {
          promiseSuccess(x);
        } else if (error) {
          error();
        }
      });
      console.info("Refreshing api key...");
      fetchData<{ apiKey: string }>(API_REFRESH_ENDPOINT, {}, function (res) {
        console.error(`Could not refresh API key: ${res}`);
      }).then((keyResp) => {
        if (keyResp?.apiKey?.trim() !== "") {
          console.info("Api key refreshed.");
          APIKey = keyResp?.apiKey!;

          //backward compat:
          if ((<any>window).hana) {
            (<any>window).hana.webservices.APIKey = APIKey;
          }

          if ((<any>window).cw) {
            (<any>window).cw.webservices.APIKey = APIKey;
          }
        }
        isFetchingApiKey = false;
      });
    }
  });
};

async function queueApiRequest<T>(
  requestId: Guid,
  endpoint?: string,
  data?: any,
  error?: Function,
  method: methodType = "post",
  fullUri?: string
): Promise<T | void> {
  return new Promise<T>(async (resolve, reject) => {
    const retry = () => {
      setTimeout(() => {
        if (isFetchingApiKey) {
          retry();
        } else {
          console.info(`Reprocessing queued api-request ${requestId}...`);
          fetchData<T>(endpoint, data, error, method, fullUri)
            .then((d) => {
              if (d) {
                console.info(`Resolved api-request ${requestId}.`);
                resolve(d);
              } else {
                console.warn(`Resolving api-request ${requestId} resulted in no result.`);
              }
            })
            .catch((err) => {
              console.warn(`Error resolving api-request ${requestId}.`);
              reject(err);
            });
        }
      }, 100);
    };

    retry();
  });
}

const getApiKey = (): string => {
  const hana = (<any>window).hana;
  const cw = (<any>window).cw;

  const appArgs = useAppStore()?.appArgs;

  if (appArgs?.ApiKey?.trim() ?? "" !== "") {
    const kee = appArgs?.ApiKey?.trim() ?? "";
    if (hana) {
      hana.webservices.APIKey = kee;
    } else if (cw) {
      cw.webservices.APIKey = kee;
    }
    return kee;
  } else if (hana) {
    return hana.webservices.APIKey;
  } else if (cw) {
    return cw.webservices.APIKey;
  }
  return "invalid or expired api key ('x-apikey' http header)";
};

const errorCallback = (response: any, endpoint: string, data: any) => {
  var title = "An error occurred";
  var body = "An unknown error occurred while attempting the last operation.";

  switch (response.status) {
    case 400:
      title = "Bad Data";
      body =
        "A malformed or incomplete request was sent to the server and could not be serviced.  If you think this message is in error please contact your system administrator and detail the steps you took to get this message.";
      break;
    case 401:
      title = "Access denied";
      body =
        'You do not currently have access to the requested resource. If you have not yet logged on our your session has expired, please <a href="/login">login</a>. If you are logged in and think this message is in error please contact your system administrator.';
      break;
    case 404:
      title = "Not found";
      body =
        "The requested resource cannot be found. If you think this message is in error please contact your system administrator and detail the steps you took to get this message.";
      break;
  }

  ui.OpenModal({
    Title: title,
    HtmlContent: body,
    OnMounted: function () {
      const legLib = (<any>window).hana ?? (<any>window).cw;

      if (legLib && legLib.loadingIndicator && typeof legLib.loadingIndicator.stop === "function") {
        legLib.loadingIndicator.stop();
      }
    },
  });
};

const hasFlushCacheParam = (): boolean =>
  !isNullOrWhiteSpace(new URLSearchParams(window.location.search).get("flushCache"));

export const hasHideHubspotChatParam = (): boolean =>
  !isNullOrWhiteSpace(new URLSearchParams(window.location.search).get("hideHubspotChat"));

export const isTestingSite = (): boolean => {
  let url = window.location.href;
  const regex = /^https:\/\/(?:localdev|staging|shipleyone.local|s1staging)/;
  return regex.test(url);
};

const addGlobalParams = (url: string): string => {
  if (hasFlushCacheParam()) {
    return `${url}${url.includes("?") ? "&" : "?"}flushCache=1`;
  }

  return url;
};
